<div class="left-block">
  <div class="StudyTab">
    <div class="studyTextDetails">
      <div class="studyIcon">
        <div class="Icon studyIconImage"></div>
      </div>
      <div class="StudyText">STUDY</div>
      <div class="toggleLeftPanel" (click)="collapsePanel()">
        <div class="toggleImage"></div>
      </div>
    </div>
    <div class="study-list-items">
      <div class="study-items-main-block"
           *ngIf="Reciveddata && Reciveddata.length == 1 || ReceivedVarData && ReceivedVarData.length == 1">
        <div class="study-items-block">
          <div class="study-name studyText-ellipsis studyText" [title]="studyname">{{ studyname }}</div>
        </div>
      </div>

      <div class="study-items-main-block"
           *ngIf="Reciveddata && Reciveddata.length > 1 || ReceivedVarData && ReceivedVarData.length >1" [ngClass]="{
          'items-main-block-select': isStudyPanel,
          disableClickEvent: isLoadSubmitted
        }" (click)="togglePanel('study', '')">
        <div class="study-items-block">
          <div class="study-name studyText-ellipsis studyText" [title]="studyname">{{ studyname }}</div>
          <div class="arrow" [ngClass]="{ hidden: isLoadSubmitted }">
            <div class="arrowToggle arrowToggleIcon" [ngClass]="{ arrowCollapsedIcon: isStudyPanel }"></div>
          </div>
        </div>
        <div class="study-sub-items" *ngIf="isStudyPanel">
          <div class="sub-items-search">
            <!--<input type="text" placeholder="Search" (input)="filterData('study','')" [(ngModel)]="searchText" name="searchText" (click)="$event.stopPropagation()" />-->
            <!-- <div class="search-icon">
              <div class="search-icon-image"></div>
            </div> -->
          </div>
          <div *ngFor="let study of filteredStudies" class="sub-items-block" [ngClass]="{
              hidden: !study
                .toString()
                .toLowerCase()
                .includes(searchText.toLowerCase())
            }" (click)="selectedStudy(study)">
            <div class="sub-items-names studyText-ellipsis" [title]="study">{{ study }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="EntryTab" *ngIf="isEntry_Tab" [ngClass]="{ 'entry-tab-height': entry_items_expand != '' }">
    <div class="EntryHeader">
      <div class="EntryIcon">
        <div class="Icon EntryIconImage"></div>
      </div>
      <div class="EntryText">
        {{ EntryPointHeader }}
      </div>
    </div>

    <div class="entry-list-items">
      <div class="entry-items-main-block" *ngFor="let entry of entryPanel; let x = index" [ngClass]="{
          'items-main-block-select': entry_items_expand == entry.Level1
        }">
        <div class="entry-list-items-block" [ngClass]="{ Select: entry_items_expand == entry.Level1 }"
             (click)="entryExpand(entry.Level1)">
          <div class="entryContentIcon">
            <div class="Icon entryContentIconImage"></div>
          </div>
          <div class="study-name">{{ entry.Level1 }}</div>
          <div class="arrow">
            <div class="arrowToggle arrowToggleIcon" [ngClass]="{
                arrowCollapsedIcon: entry_items_expand == entry.Level1
              }"></div>
          </div>
        </div>
        <div class="entry-sub-items-main leftPanelLvl0" [ngClass]="{
            'entry-sub-items-block': entry_items_expand == entry.Level1
          }">
          <div class="sub-items-search" *ngIf="entry_items_expand == entry.Level1">
            <input type="text" placeholder="Search" [(ngModel)]="searchTextLevel2" autocomplete="off"
                   name="searchTextLevel2" (input)="onSearchLevel('level1')" />
            <div class="search-icon">
              <div class="search-icon-image"></div>
            </div>
          </div>
          <div class="sub-items-main-block-container" *ngIf="entry_items_expand == entry.Level1">
            <div *ngFor="let nestedEntry of entryPanel_Lvl2" class="sub-items-block-container" [ngClass]="{
                hidden: !nestedEntry.Level2.toString()
                  .toLowerCase()
                  .includes(searchTextLevel2.toLowerCase())
              }">
              <div class="Level2EntryPoint" (click)="BindSidePanel(x, entry.Level1, nestedEntry.Level2)" [ngClass]="{
                  'side-panel-items-active':
                    entry_nested_items_expand == nestedEntry.Level2.toString()
                }">
                <div class="sub-items-name-container">
                  <div class="sub-items-names">{{ nestedEntry.Level2 }}</div>
                </div>
                <div class="market-panel-arrow">
                  <div class="arrowToggle list-open"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="side-panel-block" *ngIf="sidePanelblock && entry_items_expand == entry.Level1">
          <div class="side-panel-level-1">
            <div class="sub-items-search">
              <input type="text" placeholder="Search" [(ngModel)]="searchMetricOption" autocomplete="off"
                     name="searchMetricOption" />
              <div class="search-icon">
                <div class="search-icon-image"></div>
              </div>
            </div>
            <!--<div class="side-panel-main-container-lvl0"
                 *ngIf="entryPanel_Lvl3.length> 0 && searchMetricOption.trim() === ''">
              <div class="side-panel-child-lvl0">
                <div class="side-panel-items-lvl0"
                     title="Select All"
                     (click)="selectAll('Level1', 'Row')">
                  <div class="checkbox">
                    <div class="checkbox-icon"
                         [ngClass]="{ 'checkbox-icon-active': isSelectAll }"></div>
                  </div>
                  <div class="side-panel-item-name-container">
                    <div class="side-panel-item-name">Select All</div>
                  </div>
                </div>
              </div>
            </div>-->
            <div class="side-panel-main-container">

              <ng-container *ngIf="entryPanel_Lvl3.length> 0 && searchMetricOption.trim() === ''">
                <div class="side-panel-child-lvl0">
                  <div class="side-panel-items-lvl0" title="Select All" (click)="selectAll('Level1', 'Row')">
                    <div class="checkbox">
                      <div class="checkbox-icon" [ngClass]="{ 'checkbox-icon-active': isSelectAll }"></div>
                    </div>
                    <div class="side-panel-item-name-container">
                      <div class="side-panel-item-name">Select All</div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="side-panel-child">
                <div class="side-panel-items-main">
                  <div class="side-panel-items" *ngFor="let item of entryPanel_Lvl3; let i = index"
                       [attr.data-header]="EntryPointHeader" [ngClass]="{
                    hidden: !item.MetricOptions.toString()
                      .toLowerCase()
                      .includes(searchMetricOption.toLowerCase())
                  }" [attr.data-level]="x" [attr.data-level1]="item.Level1" [attr.data-level2]="item.Level2"
                       [attr.data-MetricOption]="item.MetricOptions" (click)="
                    checkBoxClick(
                      i,
                      x,
                      EntryPointHeader,
                      item.Level1,
                      item.Level2,
                      item.MetricOptions,
                      item.variableCode,
                      item.responseCode,
                      item.mandatory_variable_mapping,
                      false,
                     item.e1_variable_code,
                     item.e2_variable_code,
                     item.e3_variable_code,
                     item.dimension
                    )
                  ">
                    <div class="checkbox">
                      <div class="checkbox-icon" [ngClass]="{
                        'checkbox-icon-active': toggleStatus(
                          x,
                          item.Level1,
                          item.Level2,
                          item.MetricOptions
                        )
                      }"></div>
                    </div>
                    <div class="side-panel-item-name-container">
                      <div class="side-panel-item-name">
                        {{ item.MetricOptions }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-load" *ngIf="is_load_btn && this.entryPanel.length > 0">
    <div class="btn-Load-container" (click)="loadBtnClick(true)">
      <div class="btn-Load-icon">
        <div class="btn-load-icon-image"></div>
      </div>
      <div class="btn-Load-text">Load</div>
    </div>
  </div>
  <div class="btn-load" *ngIf="showCancelBtn && this.entryPanel.length > 0">
    <div class="btn-Load-container" (click)="OnCancelBtnClick()">
      <div class="btn-Load-icon">
        <div class="btn-cancel-icon-image"></div>
      </div>
      <div class="btn-Load-text">Cancel</div>
    </div>
  </div>
  <div class="variableTab" *ngIf="isVariableTab" [ngClass]="{'disabled':disableVariables}">
    <div class="variableHeader">
      <div class="variableIcon">
        <div class="Icon variableIconImage"></div>
      </div>
      <div class="variableText">VARIABLES</div>
    </div>
    <div class="left-variable-items">
      <ng-container *ngIf="!isBrandAnalysis">
             <div class="sub-items-search" >
                                    <input type="text" placeholder="Search" [(ngModel)]="leftPanelSearchVariable"
                                        autocomplete="off" name="leftPanelSearchVariable" />
                                    <div class="search-icon">
                                        <div class="search-icon-image"></div>
                                    </div>
                                </div>
        <ng-container *ngFor="let variable of variableList">
      
          <ng-container *ngIf="variable.Level0!='Weight Metrics'">
           
            <div class="variable-items-main-block"     [ngClass]="{
                      hidden: displayBucketConditionCheck(variable)
                    }">
              <div class="variables-items-block" title="{{ variable.Level0 }}" (click)="variableExpand(variable.Level0)"
                   cdkDropList [cdkDropListData]="variableList" id="variableList"
                   [cdkDropListConnectedTo]="drag_drop_container"
                   [ngClass]="{'items-main-block-select': variable_expand == variable.Level0}">
                <div class="variables-items-block-level-data" *ngIf="variable.isEntryPoint == false" 
             
>
                  <div class="entryContentIcon">
                    <div class="Icon entryContentIconImage"></div>
                  </div>
                  <div class="variable-name"
                       [ngClass]="{'highlight-entrypoint': dataFormat=='Respondent Level' && (IsDimension(variable.Level0) || variable.Level0 == 'Time Period') }">
                    {{ variable.Level0 }}
                  </div>
                  <div class="arrow" *ngIf="!variable.isEntryPoint">
                    <div class="arrowToggle arrowToggleIcon"
                         [ngClass]="{arrowCollapsedIcon: variable_expand == variable.Level0}"></div>
                  </div>
                </div>
                <div class="variables-items-block-level-data sub-items-block-1" cdkDrag *ngIf="variable.isEntryPoint "
                     [attr.data-variable-name]="variable.Level0" [attr.data-attr]="variable.Level0"
                     [attr.data-is_derived]="false" [attr.data-is_brand_analysis]=7 [attr.data-calc_type]="false"
                     [attr.data-hasNextLevel]="true" [attr.data-is_dimension]="false" [attr.data-is_CustomGroup]="false" [attr.data-is_CustomCalculation]="false"
                     [attr.data-isEntryPoint]="variable.isEntryPoint"
                     [attr.data-kibanaFilterScript]="" [attr.data-customGroupId]="" (mousedown)="onItemClicked($event)">
                  <div class="entryContentIcon">
                    <div class="Icon entryContentIconImage"></div>
                  </div>
                  <div class="variable-name"
                       [ngClass]="{'highlight-entrypoint':dataFormat=='Respondent Level' &&(variable.isEntryPoint || variable.Level0 == 'Time Period')}">
                    {{
                    variable.Level0
                    }}
                  </div>
                  <div class="arrow" *ngIf="!variable.isEntryPoint">
                    <div class="arrowToggle arrowToggleIcon"
                         [ngClass]="{arrowCollapsedIcon: variable_expand == variable.Level0}"></div>
                  </div>
                </div>
              </div>
              <div class="variables-sub-items"
                   *ngIf="variable_expand == variable.Level0 && variable.isEntryPoint == false">
                <div class="sub-items-search" *ngIf="variable_expand == variable.Level0">
                  <input type="text" placeholder="Search" [(ngModel)]="searchVariable" autocomplete="off"
                         name="searchVariable" />
                  <div class="search-icon">
                    <div class="search-icon-image"></div>
                  </div>
                </div>

                <div *ngIf="variable_expand == variable.Level0" cdkDropList #personsList="cdkDropList"
                     [cdkDropListData]="variableList" id="variableList" [cdkDropListConnectedTo]="drag_drop_container">
                  <ng-container *ngFor="let Level1 of variable.Level1">
                    <!-- <div *ngIf="(Level1.calc_type.toString()== 'mean' && isCreateCustomGroup) ? false : true" -->
                     <div *ngIf="((Level1.calc_type.toString() === 'mean' && isCreateCustomCalculation)&&(!isCreateCustomGroup)) || (!(Level1.calc_type.toString()=== 'mean' && isCreateCustomGroup) && (!isCreateCustomCalculation))?true:false"
                         title="{{ Level1.tooltip }}" [attr.data-variable-name]="Level1.Level1_value"
                         [attr.data-attr]="variable.Level0" [attr.data-is_brand_analysis]="Level1.is_brand_analysis"
                         [attr.data-is_derived]="Level1.is_derived" [attr.data-calc_type]="Level1.calc_type"
                         [attr.data-hasNextLevel]="Level1.hasNextLevel"
                         [attr.data_variable_code]="Level1.data_variable_code"
                         [attr.data_response_code]="Level1.response_code" [attr.data_sort_id]="Level1.sort_id"
                         [attr.data_is_multi_punch]="Level1.is_multi_punch" [attr.data_date]="Level1.date"
                         [attr.data_year_code]="Level1.year_code" [attr.data-is_dimension]="Level1.is_dimension"
                         [attr.data-is_CustomGroup]="Level1.isCustomGrpPresent"
                         [attr.data-is_CustomCalculation]="Level1.isCustomCalcPresent"
                         [attr.data-kibanaFilterScript]="Level1.kibanaFilterScript"
                         [attr.data-customGroupId]="Level1.customGroupId" 
                         [attr.data-customCalculationId]="Level1.customCalculationId" 
                         [attr.data-dataYearCode]="Level1.dataYearCode"
                         [attr.data-isEntryPoint]="variable.isEntryPoint"
                         [attr.data-is_time_Period_Present]="Level1.is_time_Period_Present" class="sub-items-block" cdkDrag
                         (mousedown)="onItemClicked($event)" [ngClass]="{
                  hidden: !Level1.Level1_value.toLowerCase().includes(
                    searchVariable.toLowerCase() || leftPanelSearchVariable.toLowerCase()
                  ) 
                }">
                      <div class="entryContentIcon">
                        <div class="Icon entryContentIconImage"></div>
                      </div>
                      <div class="sub-items-names-parent">
                        <div class="sub-items-names">{{ Level1.Level1_value }}</div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isBrandAnalysis && (brandIndependentVariableList.length>0 || brandDependentVariableList.length>0)">
        <div class="sub-items-search" >
          <input type="text" placeholder="Search" [(ngModel)]="leftPanelSearchVariable"
              autocomplete="off" name="leftPanelSearchVariable" />
          <div class="search-icon">
              <div class="search-icon-image"></div>
          </div>
      </div>
      <ng-container *ngIf="!isCreateCustomCalculation">
        <div class="brandIndependentSection" *ngIf="brandIndependentVariableList.length>0">Brand Independent</div>
        <ng-container *ngFor="let variable of brandIndependentVariableList">
          <ng-container *ngIf="variable.Level0!='Weight Metrics'">
            <div class="variable-items-main-block"    [ngClass]="{
                      hidden: displayBucketConditionCheck(variable,'brandAnalysis')
                    }" >
              <div class="variables-items-block" title="{{ variable.Level0 }}"
                   (click)="variableExpand('BI-'+variable.Level0)" cdkDropList [cdkDropListData]="variableList"
                   id="variableList" [cdkDropListConnectedTo]="drag_drop_container"
                   [ngClass]="{'items-main-block-select': variable_expand == 'BI-'+variable.Level0}">
                <div class="variables-items-block-level-data" *ngIf="variable.isEntryPoint == false">
                  <div class="entryContentIcon">
                    <div class="Icon entryContentIconImage"></div>
                  </div>
                  <div class="variable-name"
                       [ngClass]="{'highlight-entrypoint': IsDimension(variable.Level0) || variable.Level0 == 'Time Period' }">
                    {{ variable.Level0 }}
                  </div>
                  <div class="arrow" *ngIf="!variable.isEntryPoint">
                    <div class="arrowToggle arrowToggleIcon"
                         [ngClass]="{arrowCollapsedIcon: variable_expand == 'BI-'+variable.Level0}"></div>
                  </div>
                </div>
                <div class="variables-items-block-level-data sub-items-block-1" cdkDrag *ngIf="variable.isEntryPoint"
                     [attr.data-variable-name]="variable.Level0" [attr.data-attr]="variable.Level0"
                     [attr.data-is_derived]="false" [attr.data-is_brand_analysis]=7 [attr.data-calc_type]="false"
                     [attr.data-hasNextLevel]="true" [attr.data-is_dimension]="false" [attr.data-is_CustomGroup]="false"
                     [attr.data-is_CustomCalculation]="false" 
                     [attr.data-isEntryPoint]="variable.isEntryPoint"
                     [attr.data-kibanaFilterScript]="" [attr.data-customGroupId]="" (mousedown)="onItemClicked($event)">
                  <div class="entryContentIcon">
                    <div class="Icon entryContentIconImage"></div>
                  </div>
                  <div class="variable-name"
                       [ngClass]="{'highlight-entrypoint': variable.isEntryPoint || variable.Level0 == 'Time Period'}">
                    {{
                    variable.Level0
                    }}
                  </div>
                  <div class="arrow" *ngIf="!variable.isEntryPoint">
                    <div class="arrowToggle arrowToggleIcon"
                         [ngClass]="{arrowCollapsedIcon: variable_expand == 'BI-'+variable.Level0}"></div>
                  </div>
                </div>
              </div>
              <div class="variables-sub-items"
                   *ngIf="variable_expand == 'BI-'+variable.Level0 && variable.isEntryPoint == false">
                <div class="sub-items-search" *ngIf="variable_expand == 'BI-'+variable.Level0">
                  <input type="text" placeholder="Search" [(ngModel)]="searchVariable" autocomplete="off"
                         name="searchVariable" />
                  <div class="search-icon">
                    <div class="search-icon-image"></div>
                  </div>
                </div>

                <div *ngIf="variable_expand == 'BI-'+variable.Level0" cdkDropList #personsList="cdkDropList"
                     [cdkDropListData]="variableList" id="variableList" [cdkDropListConnectedTo]="drag_drop_container">
                  <ng-container *ngFor="let Level1 of variable.Level1">
                    <!-- <div *ngIf="(Level1.calc_type.toString()== 'mean' && isCreateCustomGroup) ? false : true && Level1.is_brand_analysis === 0 || Level1.is_brand_analysis === 7" -->
                     <div *ngIf="((!(Level1.calc_type.toString() === 'mean' && isCreateCustomCalculation)&&(!isCreateCustomGroup))&&( Level1.is_brand_analysis === 0|| Level1.is_brand_analysis === 7) ) || ((!(Level1.calc_type.toString()=== 'mean' && isCreateCustomGroup)&& (Level1.is_brand_analysis === 0|| Level1.is_brand_analysis === 7)) && (!isCreateCustomCalculation))?true:false"
                         title="{{ Level1.tooltip }}" [attr.data-variable-name]="Level1.Level1_value"
                         [attr.data-attr]="variable.Level0" [attr.data-is_brand_analysis]="Level1.is_brand_analysis"
                         [attr.data-is_derived]="Level1.is_derived" [attr.data-calc_type]="Level1.calc_type"
                         [attr.data-hasNextLevel]="Level1.hasNextLevel"
                         [attr.data_variable_code]="Level1.data_variable_code"
                         [attr.data_response_code]="Level1.response_code" [attr.data_sort_id]="Level1.sort_id"
                         [attr.data_is_multi_punch]="Level1.is_multi_punch" [attr.data_date]="Level1.date"
                         [attr.data_year_code]="Level1.year_code" [attr.data-is_dimension]="Level1.is_dimension"
                         [attr.data-is_CustomGroup]="Level1.isCustomGrpPresent"
                          [attr.data-is_CustomCalculation]="Level1.isCustomCalcPresent"
                         [attr.data-kibanaFilterScript]="Level1.kibanaFilterScript"
                         [attr.data-customCalculationId]="Level1.customCalculationId" 
                         [attr.data-isEntryPoint]="variable.isEntryPoint"
                         [attr.data-customGroupId]="Level1.customGroupId" [attr.data-dataYearCode]="Level1.dataYearCode"
                         [attr.data-is_time_Period_Present]="Level1.is_time_Period_Present" class="sub-items-block" cdkDrag
                         (mousedown)="onItemClicked($event)" [ngClass]="{
                    hidden: !Level1.Level1_value.toLowerCase().includes(
                      searchVariable.toLowerCase() || leftPanelSearchVariable.toLowerCase()
                    )
                  }">
                      <div class="entryContentIcon">
                        <div class="Icon entryContentIconImage"></div>
                      </div>
                      <div class="sub-items-names-parent">
                        <div class="sub-items-names">{{ Level1.Level1_value }}</div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
        <ng-container *ngIf="!isCreateCustomGroup">
          <div class="brandDependentSection">Brand Dependent</div>
          <ng-container *ngFor="let variable of brandDependentVariableList">
            <ng-container *ngIf="variable.Level0!='Weight Metrics'">
              <div class="variable-items-main-block" [ngClass]="{
                      hidden: displayBucketConditionCheck(variable,'brandAnalysis')
                    }">
                <div class="variables-items-block" title="{{ variable.Level0 }}"
                     (click)="variableExpand('BD-'+variable.Level0)" cdkDropList [cdkDropListData]="variableList"
                     id="variableList" [cdkDropListConnectedTo]="drag_drop_container"
                     [ngClass]="{'items-main-block-select': variable_expand == 'BD-'+variable.Level0}">
                  <div class="variables-items-block-level-data" *ngIf="variable.isEntryPoint == false">
                    <div class="entryContentIcon">
                      <div class="Icon entryContentIconImage"></div>
                    </div>
                    <div class="variable-name"
                         [ngClass]="{'highlight-entrypoint': IsDimension(variable.Level0) || variable.Level0 == 'Time Period' }">
                      {{ variable.Level0 }}
                    </div>
                    <div class="arrow" *ngIf="!variable.isEntryPoint">
                      <div class="arrowToggle arrowToggleIcon"
                           [ngClass]="{arrowCollapsedIcon: variable_expand == 'BD-'+variable.Level0}"></div>
                    </div>
                  </div>
                  <div class="variables-items-block-level-data sub-items-block-1" cdkDrag *ngIf="variable.isEntryPoint"
                       [attr.data-variable-name]="variable.Level0" [attr.data-attr]="variable.Level0"
                       [attr.data-is_derived]="false" [attr.data-is_brand_analysis]=7 [attr.data-calc_type]="false"
                       [attr.data-hasNextLevel]="true" [attr.data-is_dimension]="false" [attr.data-is_CustomGroup]="false" [attr.data-is_CustomCalculation]="false"
                       [attr.data-isEntryPoint]="variable.isEntryPoint"
                       [attr.data-kibanaFilterScript]="" [attr.data-customGroupId]="" (mousedown)="onItemClicked($event)">
                    <div class="entryContentIcon">
                      <div class="Icon entryContentIconImage"></div>
                    </div>
                    <div class="variable-name"
                         [ngClass]="{'highlight-entrypoint': variable.isEntryPoint || variable.Level0 == 'Time Period'}">
                      {{
                    variable.Level0
                      }}
                    </div>
                    <div class="arrow" *ngIf="!variable.isEntryPoint">
                      <div class="arrowToggle arrowToggleIcon"
                           [ngClass]="{arrowCollapsedIcon: variable_expand == 'BD-'+variable.Level0}"></div>
                    </div>
                  </div>
                </div>
                <div class="variables-sub-items"
                     *ngIf="variable_expand == 'BD-'+variable.Level0 && variable.isEntryPoint == false">
                  <div class="sub-items-search" *ngIf="variable_expand == 'BD-'+variable.Level0">
                    <input type="text" placeholder="Search" [(ngModel)]="searchVariable" autocomplete="off"
                           name="searchVariable" />
                    <div class="search-icon">
                      <div class="search-icon-image"></div>
                    </div>
                  </div>

                  <div *ngIf="variable_expand == 'BD-'+variable.Level0" cdkDropList #personsList="cdkDropList"
                       [cdkDropListData]="variableList" id="variableList" [cdkDropListConnectedTo]="drag_drop_container">
                    <ng-container *ngFor="let Level1 of variable.Level1">
                      <!-- <div *ngIf="(Level1.calc_type.toString()== 'mean' && isCreateCustomGroup) ? false : true && Level1.is_brand_analysis !== 0 && Level1.is_brand_analysis !== 7" -->
                      <div *ngIf="(((Level1.calc_type.toString() === 'mean' && isCreateCustomCalculation)&&(!isCreateCustomGroup))&&( Level1.is_brand_analysis !== 0 && Level1.is_brand_analysis !== 7) ) || ((!(Level1.calc_type.toString()=== 'mean' && isCreateCustomGroup)&& (Level1.is_brand_analysis != 0 && Level1.is_brand_analysis !== 7)) && (!isCreateCustomCalculation))?true:false"
                           title="{{ Level1.tooltip }}" [attr.data-variable-name]="Level1.Level1_value"
                           [attr.data-attr]="variable.Level0" [attr.data-is_brand_analysis]="Level1.is_brand_analysis"
                           [attr.data-is_derived]="Level1.is_derived" [attr.data-calc_type]="Level1.calc_type"
                           [attr.data-hasNextLevel]="Level1.hasNextLevel"
                           [attr.data_variable_code]="Level1.data_variable_code"
                           [attr.data_response_code]="Level1.response_code" [attr.data_sort_id]="Level1.sort_id"
                           [attr.data_is_multi_punch]="Level1.is_multi_punch" [attr.data_date]="Level1.date"
                           [attr.data_year_code]="Level1.year_code" [attr.data-is_dimension]="Level1.is_dimension"
                           [attr.data-is_CustomGroup]="Level1.isCustomGrpPresent"
                            [attr.data-is_CustomCalculation]="Level1.isCustomCalcPresent"
                           [attr.data-kibanaFilterScript]="Level1.kibanaFilterScript"
                           [attr.data-customCalculationId]="Level1.customCalculationId" 
                           [attr.data-customGroupId]="Level1.customGroupId" [attr.data-dataYearCode]="Level1.dataYearCode"
                           [attr.data-isEntryPoint]="variable.isEntryPoint"
                           [attr.data-is_time_Period_Present]="Level1.is_time_Period_Present" class="sub-items-block" cdkDrag
                           (mousedown)="onItemClicked($event)" [ngClass]="{
                    hidden: !Level1.Level1_value.toLowerCase().includes(
                      searchVariable.toLowerCase()
                    )
                  }">
                        <div class="entryContentIcon">
                          <div class="Icon entryContentIconImage"></div>
                        </div>
                        <div class="sub-items-names-parent">
                          <div class="sub-items-names">{{ Level1.Level1_value }}</div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
